import checkSession from '../helpers/checkSession';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'modal',
    'overlay',
    'title',
    'content',
    'requiredQuestionnaire',
    'questionnaireCol',
    'questionnaireList',
  ];

  connect() {
    super.connect();
    document.addEventListener('openModal', this.openModal);
    document.addEventListener('closeModal', this.closeModal.bind(this));
    this.element[this.identifier] = this;
  }

  disconnect() {
    document.removeEventListener('openModal', this.openModal);
    document.addEventListener('closeModal', this.closeModal.bind(this));
    this.closeModal();
  }

  initialize() {
    checkSession(this);
  }

  questionnaireListTargetConnected() {
    if (this.requiredQuestionnaireTarget.checked) {
      this.toggleRequiredQuestionnaire();
    }
  }

  openModal(event) {
    const { html, title, overlayOpacity, modalContainerCss } = event.detail;

    const modal = document.querySelector('.modal');

    if (!modal) return;

    const modalTitle = document.getElementById('modal-title');
    const modalContent = document.getElementById('modal-content');
    const overlay = document.querySelector('.modal-overlay');
    const modalContainer = document.querySelector('.modal-container');

    modal.classList.add('opacity-100', 'visible');
    modal.classList.remove('opacity-0', 'invisible', 'modal');

    overlay.classList.add(overlayOpacity, 'visible');
    overlay.classList.remove('bg-opacity-0', 'invisible');

    if (modalTitle && title) modalTitle.insertAdjacentText('beforeend', title);
    modalContent.insertAdjacentHTML('beforeend', html);

    if (modalContainerCss && modalContainer) {
      modalContainer.classList = modalContainerCss;
    }
  }

  closeModal(event) {
    if (event) event.preventDefault();
    this.modalTarget.classList.remove('opacity-100', 'visible');
    this.modalTarget.classList.add('opacity-0', 'invisible', 'modal');
    this.overlayTarget.classList.remove(
      'bg-opacity-75',
      'bg-opacity-100',
      'visible',
    );
    this.overlayTarget.classList.add('bg-opacity-0', 'invisible');
    if (this.hasTitleTarget) {
      this.inactivateSessionModal();
      this.titleTarget.innerHTML = '';
    }
    this.contentTarget.innerHTML = '';
  }

  inactivateSessionModal() {
    if (this.titleTarget.textContent.toLowerCase().includes('timeout')) {
      window.sessionModalActive = false;
    }
  }

  toggleRequiredQuestionnaire() {
    this.questionnaireColTarget.classList.toggle('hidden');
    this.questionnaireListTarget.required =
      !this.questionnaireListTarget.required;
  }

  stopPropagation(event) {
    event.stopPropagation();
  }  
}
